import { defineAbility } from '@casl/ability'

// export empty can function since permissions are dynamically requested from backend api after initial authentication
export default defineAbility(can => {
  can()
})

/*
const ability = new Ability([
  { subject: 'all', actions: 'manage' },
  { subject: 'Post', actions: 'delete', inverted: true }
])
*/
