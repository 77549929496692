<template>
  <div>
    <profile-wrapper />
  </div>
</template>

<script>
import ProfileWrapper from '@/components/UserProfile'

export default {
  components: {
    profileWrapper: ProfileWrapper
  }
}
</script>

<style>
</style>
