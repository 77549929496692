<template>
  <v-dialog
    scrollable
    max-width="1000px"
    v-model="dialog"
    @click:outside="close()"
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>Value history</v-toolbar-title>

          <template v-slot:extension>
            <v-tabs v-model="model" centered>
              <v-tab :href="`#tab-1`"> Timeline </v-tab>
              <v-tab :href="`#tab-2`"> Table </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
      </v-card-title>

      <v-card-text style="max-height: 600px; min-height: 300px">
        <v-tabs-items v-model="model">
          <v-tab-item :value="`tab-1`">
            <!--<v-switch label="Toggle direction"></v-switch>-->
            <v-timeline dense align-top>
              <!-- entry -->
              <v-timeline-item
                v-for="entry in entries"
                :key="entry.unique"
                color="success"
                fill-dot
                :icon="getIcon(entry.action)"
              >
                <!-- right -->
                <v-row>
                  <v-col cols="3" class="text-center">
                    <strong v-text="entry.timestamp_local" />
                  </v-col>
                  <v-col cols="3" class="text-center">
                    <strong>{{ entry.action }}</strong>
                    <div class="caption mb-2">{{ entry.user }}</div>
                  </v-col>
                  <v-col>
                    <v-row no-gutters>
                      <v-col cols="2" class="text-right">Value</v-col>
                      <v-col cols="1"></v-col>
                      <v-col cols="9">{{ entry.value }}</v-col>
                      <v-col cols="2" class="text-right">Comment</v-col>
                      <v-col cols="1"></v-col>
                      <v-col cols="9">{{ entry.comment }}</v-col>
                      <v-col cols="2" class="text-right">Way</v-col>
                      <v-col cols="1"></v-col>
                      <v-col cols="9">{{ entry.way }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!-- entry end -->
            </v-timeline>
          </v-tab-item>
          <v-tab-item :value="`tab-2`">
            <v-data-table
              :headers="headers"
              :items="entries"
              :search="search"
              show-group-by
            >
              <template v-slot:body.append="{ headers }">
                <tr>
                  <td :colspan="headers.length">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </td>
                </tr> </template
            ></v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/*
const ICONS = {
  create: 'add',
  upadte: 'edit'
}

const COLOR = {
  create: 'success',
  upadte: 'info'
}
*/

export default {
  props: {
    dialog: {
      type: Boolean,
    },
    entries: {
      type: Array,
    },
  },

  data() {
    return {
      model: "tab-1",
      headers: [
        { text: "Action", value: "action" },
        { text: "Comment", value: "comment", groupable: false },
        { text: "Timestamp", value: "timestamp_local", groupable: false },
        { text: "User", value: "user" },
        { text: "Value", value: "value", groupable: false },
        { text: "Way", value: "way" },
      ],
      search: "",
      reverse: false,
    };
  },

  computed: {
    sortedEntries() {
      return this.entries((entry) => {
        return entry;
      });
    },
  },

  methods: {
    getIcon(action) {
      return action === "update" ? "edit" : "add";
      // return ICONS[action]
    },
    getColor(action) {
      return action === "update" ? "info" : "success";
      // return COLOR[action]
    },
    close() {
      this.$emit("close-dialog", false);
    },
  },
};
</script>
