import Vue from 'vue'
import Vuex from 'vuex'
// vuex modules
import auth from './store/modules/auth'
import initialize from './store/modules/initialize'
import configuration from './store/modules/configuration'
import user from './store/modules/user'
import inbox from './store/modules/inbox'
import permission from './store/modules/permission'
import role from './store/modules/role'
import logs from './store/modules/logs'
import masterdata from './store/modules/masterdata'
import session from './store/modules/session'
import global from './store/modules/global'
import user2 from './store/modules/user/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    masterdata,
    initialize,
    configuration,
    logs,
    user,
    permission,
    role,
    session,
    inbox,
    global,
    user2
  }
})
