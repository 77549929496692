// creates backend api URL based on current filter settings
// input: filter as obj
// output: URL as sting
export function createUrl(filters) {
    let url = "";
    for (const [i, filter] of filters.filter.entries()) {
        let field = filter.field.value;
        let condition = filter.chain;
        url += field + "=";
        for (const [index, child] of filter.children.entries()) {
            url += child.operator + "." + child.value;
            if (index + 1 !== filter.children.length) url += ",";
            else url += "_" + condition;
        }
        if (i + 1 !== filters.filter.length) url += "&";
    }
    url += "&and_or=" + filters.condition;
    return url
}