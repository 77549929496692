<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
      wrap
    >
      <v-flex
        xs12
        sm8
        md8
        lg4
      >
        <div class="display-3 text-center pb-12 hidden-sm-and-down">
          OpenGxP FORMS
        </div>
        <v-card class="elevation-12">
          <v-card-title
            primary-title
            class="justify-center"
          >
            <span class="title font-weight-light">Login</span>
          </v-card-title>
          <v-card-text>
            <v-form class="px-3">
              <v-text-field
                v-model="username"
                autofocus
                prepend-icon="person"
                name="login"
                label="Login"
                type="text"
                :error="('username' in errMsgs)"
                :error-messages="errMsgs.username"
                @keyup.enter="login()"
              />
              <v-text-field
                id="password"
                v-model="password"
                prepend-icon="lock"
                :append-icon="show ? 'visibility_off' : 'visibility'"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                name="password"
                label="Password"
                :error="('password' in errMsgs)"
                :error-messages="errMsgs.password"
                @click:append="show = !show"
                @keyup.enter="login()"
              />
              <p class="text-right">
                <router-link
                  style="text-decoration: none;"
                  to="/recoveraccount"
                >
                  Forgot your password?
                </router-link>
              </p>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-center pb-6">
            <v-btn
              color="primary"
              @click="login"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',

  data: () => ({
    errMsgs: [],
    color: 'primary',
    username: '',
    password: '',
    show: false,
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters'
    }
  }),

  methods: {
    ...mapActions({
      init: 'auth/initSession'
    }),
    login () {
      this.$store.dispatch('auth/authenticationAtttempt', {
        'username': this.username,
        'password': this.password
      })
        .then(resp => {
          if (resp.data.initial_password || resp.data.initial_timezone) {
            this.$router.push('/newpassword')
          } else {
            this.init()
          }
        })
        .catch(err => {
          if (err.response.status === 403) {
            this.login()
          } else {
            this.errMsgs = err.response.data
          }
        })
    }
  }
}
</script>
