<template>
  <div>
    <app-inbox />
  </div>
</template>

<script>
import AppInbox from '@/components/TheInbox'

export default {
  components: {
    appInbox: AppInbox
  }
}
</script>

<style>
</style>
