import axios from 'axios'
import router from '@/router'
import ability from '@/ability'
import vuetify from '@/plugins/vuetify'
import authApi from '@/services/api/authentication'
// import { USER_REQUEST } from '../actions/user'
// import { GET_INBOX } from '../actions/session'
// import authentication from '@/services/api/authentication'

const state = {
  isAuthenticated: false,
  token: '',
  errMsg: ''
}

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  errMsg: state => state.errMsg
}

const actions = {
  authenticationAtttempt({ commit, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      authApi.login(credentials)
        .then(resp => {
          // get auth token
          dispatch('getToken')
          // set user attributes
          dispatch('setInitialAttributeState', {
            'initialPassword': resp.data.initial_password,
            'initialTimezone': resp.data.initial_timezone
          }, { root: true })
          commit('user2/setUsername', credentials.username, { root: true })
          // update casl permissions
          // since casl uses keyword "action" per default and api supplies "actions", it needs to be replaced
          const permissions = resp.data.casl
          ability.update(permissions.map( ({actions: action, ...permissions}) => ({action, ...permissions})))
          resolve(resp)
        })
        .catch(err => {
          if (err.response.status === 403) {
            // replace old token
            dispatch('getToken')
            setTimeout(reject(err), 1000)
          } else {
            reject(err)
          }
        })
    })
  },
  logout: ({ commit }) => {
    axios({
      method: 'GET',
      url: '/logout',
      withCredentials: true
    })
      .then(() => {
        commit('logout')
        vuetify.framework.theme.dark = true
        router.push('/login')
      })
  },
  lo: ({ commit }) => {
    commit('logout')
    vuetify.framework.theme.dark = true
    router.push('/login')
  },
  getToken: ({ commit }) => {
    authApi.getToken()
      .then(resp => {
        commit('setToken', resp.data.csrftoken)
      })
      .catch()
  },
  // initialize session
  initSession: ({ dispatch, commit }) => {
    // calls /_root to initialize navigation drawer
    // TODO: refactor, move to different module and use namespace
    dispatch('initNavigationDrawer', null, { root: true })
    // initialize
    dispatch('initUser', null, { root: true })
    dispatch('user2/getProfileData', null, { root: true })
    // check inbox
    dispatch('session/getInbox', null, { root: true })
    commit('login')
    router.push('/')
  }
  /*
  setNewPassword: ({ commit }, credentials) => {
    axios.patch('fwadad', credentials)
      .then(resp => {})
      .catch(err => {})
  }
  */
}

const mutations = {
  login: (state) => {
    state.isAuthenticated = true
  },
  failedAttempt: (state, msg) => {
    state.errMsg = msg
  },
  logout: (state) => {
    if (state.token) {
      state.token = ''
    }
    state.isAuthenticated = false
  },
  setToken: (state, token) => {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRFTOKEN': token
    }
    state.token = token
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
