var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-navigation-drawer',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}],attrs:{"user":_vm.username},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}}),_c('v-navigation-drawer',{attrs:{"absolute":"","right":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"block":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Logout")])],1)]},proxy:true}]),model:{value:(_vm.drawer2),callback:function ($$v) {_vm.drawer2=$$v},expression:"drawer2"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/profile"}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("person")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Profile")])],1)],1)],1)],1),_c('v-app-bar',{attrs:{"app":"","color":"primary","fixed":"","collapse":!_vm.collapse}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',{staticClass:"ml-0 pl-4",staticStyle:{"width":"300px"}},[_c('span',{staticClass:"hidden-sm-and-down"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"to":"/"}},[_c('span',{staticClass:"font-weight-light"},[_vm._v("Open")]),_c('span',[_vm._v("GxP")])])],1)]),_c('v-spacer'),_c('app-search'),(_vm.collapse)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-sm-and-down",attrs:{"icon":""},on:{"click":function($event){return _vm.toInbox()}}},on),[(_vm.numInbox)?_c('v-badge',{attrs:{"overlap":"","color":"warning"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(_vm._s(_vm.numInbox))]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("mail")])],1):_c('v-icon',[_vm._v("mail")])],1)]}}],null,false,1511331166)},[_c('span',[_vm._v("Inbox")])]):_vm._e(),(_vm.collapse)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-sm-and-down",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("settings")])],1)]}}],null,true)},[_c('span',[_vm._v("Settings")])])]}}],null,false,1224679325)},[_c('v-card',{attrs:{"min-width":"200px"}},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.navigate(item.url)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.test()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("help_outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Show Hotkeys')}})],1)],1)],2)],1)],1):_vm._e(),_c('span',{domProps:{"textContent":_vm._s(_vm.username)}}),(_vm.username && _vm.collapse)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-sm-and-down",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("person")])],1)]}}],null,true)},[_c('span',[_vm._v("Profil & Account")])])]}}],null,false,144672705)},[_c('v-card',{attrs:{"min-width":"200px"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Profile")]),_vm._l((_vm.profileItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.navigate(item.url)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Log out")])],1)],1)],2)],1)],1):_vm._e(),_c('v-btn',{staticClass:"hidden-md-and-up",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.drawer2 = !_vm.drawer2}}},[_c('v-icon',[_vm._v("more_vert")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }