<template>

  <v-row>
    <v-col cols="4">
      <v-card>
        <v-card-title>
          Notifications
        </v-card-title>
        <v-card-text>
          <p>
            You have no new noftifications
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
export default {
  data () {
    return {
      steps: ''
    }
  }
}
</script>
