import axios from 'axios'

const state = {
  baseConfiguration: []
}

const getters = {
  data: (state) => state.baseConfiguration.navigation
  /*
  logs: (state) => state.baseConfiguration.navigation.logs,
  user: (state) => state.baseConfiguration.user
  */
}

const actions = {
  initNavigationDrawer: ({ commit }) => {
    axios.get('/_root')
      .then(resp => {
        commit('initNavigationDrawer', resp.data)
      })
  }
}

const mutations = {
  initNavigationDrawer: (state, payload) => {
    state.baseConfiguration = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
