var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"color":"secondary"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.sections),function(section){return _c('v-tab',{key:section.sectionSequence},[_c('v-badge',{attrs:{"value":_vm.eee.includes(section.sectionSequence.toString()),"color":"error","dot":""}},[_c('span',{domProps:{"textContent":_vm._s(section.sectionName)}})])],1)}),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.addSection()}}},[_c('v-icon',[_vm._v("add")])],1)],2)]},proxy:true}])},[_c('v-toolbar-title',[_vm._v("Form Builder")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.parseInternalModel()}}},[_c('v-icon',[_vm._v("save")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("close")])],1)],1)],1),_c('v-card-text',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.sections),function(section){return _c('v-tab-item',{key:section.sectionSequence},[_c('v-row',[_c('v-container',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"secondary"}},[_c('v-card-title',[_vm._v(" Section Head "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","disabled":_vm.sections.length === 1},on:{"click":function($event){return _vm.deleteSection(section.sectionSequence)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Section")])])],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('app-text-field',{attrs:{"counter":_vm.meta.section.section.max_length,"hint":_vm.meta.section.section.help_text,"label":_vm.meta.section.section.verbose_name,"required":_vm.meta.section.section.required,"maxlength":_vm.meta.section.section.max_length,"editable":_vm.isReferenced(section.sectionName),"errormsgs":_vm.getSectionErrorMsgs(
                            section.sectionSequence,
                            'sections',
                            'section'
                          )},model:{value:(section.sectionName),callback:function ($$v) {_vm.$set(section, "sectionName", $$v)},expression:"section.sectionName"}})],1),_c('v-col',[_c('app-select',{attrs:{"hint":_vm.meta.section.role.help_text,"items":_vm.meta.section.role.lookup.data,"label":_vm.meta.section.role.verbose_name,"required":_vm.meta.section.role.required,"errormsgs":_vm.getSectionErrorMsgs(
                            section.sectionSequence,
                            'sections',
                            'role'
                          )},model:{value:(section.sectionRole),callback:function ($$v) {_vm.$set(section, "sectionRole", $$v)},expression:"section.sectionRole"}})],1),_c('v-col',[_c('app-select',{attrs:{"hint":_vm.meta.section.confirmation.help_text,"items":_vm.meta.section.confirmation.lookup.data,"label":_vm.meta.section.confirmation.verbose_name,"required":_vm.meta.section.confirmation.required,"errormsgs":_vm.getSectionErrorMsgs(
                            section.sectionSequence,
                            'sections',
                            'confirmation'
                          )},model:{value:(section.sectionConfirmationType),callback:function ($$v) {_vm.$set(section, "sectionConfirmationType", $$v)},expression:"section.sectionConfirmationType"}})],1),_c('v-col',[(section.sectionSequence > 1)?_c('app-select',{attrs:{"items":_vm.predecessors.filter(
                            function (name) { return name !== section.sectionName; }
                          ),"hint":_vm.meta.section.predecessors.help_text,"label":_vm.meta.section.predecessors.verbose_name,"required":_vm.meta.section.predecessors.required,"multiple":"","errormsgs":_vm.getSectionErrorMsgs(
                            section.sectionSequence,
                            'sections',
                            'predecessors'
                          )},model:{value:(section.sectionPredecessors),callback:function ($$v) {_vm.$set(section, "sectionPredecessors", $$v)},expression:"section.sectionPredecessors"}}):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',_vm._l((section.sectionFields),function(field){return _c('v-expansion-panel',{key:field.fieldSequence},[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":"","color":"secondary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var open = ref.open;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('span',{domProps:{"textContent":_vm._s(
                              field.fieldName
                                ? ((field.fieldSequence) + " - " + (field.fieldName))
                                : ((field.fieldSequence) + " - Field Name")
                            )}})]),_c('v-col',{staticClass:"text--secondary",attrs:{"cols":"8"}},[(!open)?_c('span',[_vm._v(_vm._s(field.fieldType))]):_vm._e()])],1)]}},{key:"actions",fn:function(){return [(
                          _vm.getFieldErrorMsgs(
                            section.sectionSequence,
                            field.fieldSequence,
                            field.fieldType === 'Text'
                              ? 'fields_text'
                              : 'fields_bool',
                            ''
                          )
                        )?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("error")]):_vm._e()]},proxy:true}],null,true)}),_c('v-expansion-panel-content',{attrs:{"color":"secondary"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":"Field Type","items":_vm.fieldTypes},model:{value:(field.fieldType),callback:function ($$v) {_vm.$set(field, "fieldType", $$v)},expression:"field.fieldType"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('app-text-field',{attrs:{"counter":_vm.meta.fields.field.max_length,"hint":_vm.meta.fields.field.help_text,"label":_vm.meta.fields.field.verbose_name,"required":_vm.meta.fields.field.required,"maxlength":_vm.meta.fields.field.max_length,"errormsgs":_vm.getFieldErrorMsgs(
                                  section.sectionSequence,
                                  field.fieldSequence,
                                  field.fieldType === 'Text'
                                    ? 'fields_text'
                                    : 'fields_bool',
                                  'field'
                                )},model:{value:(field.fieldName),callback:function ($$v) {_vm.$set(field, "fieldName", $$v)},expression:"field.fieldName"}})],1),_c('v-col',{attrs:{"cols":"4"}},[(field.fieldType === 'Text')?_c('app-text-field',{attrs:{"counter":_vm.meta.fields.default.max_length,"hint":_vm.meta.fields.default.help_text,"label":_vm.meta.fields.default.verbose_name,"required":_vm.meta.fields.default.required,"maxlength":_vm.meta.fields.default.max_length},model:{value:(field.fieldDefault),callback:function ($$v) {_vm.$set(field, "fieldDefault", $$v)},expression:"field.fieldDefault"}}):(field.fieldType === 'Select')?_c('v-select',{attrs:{"items":['one', 'two'],"label":_vm.meta.fields.default.verbose_name},model:{value:(field.fieldDefault),callback:function ($$v) {_vm.$set(field, "fieldDefault", $$v)},expression:"field.fieldDefault"}}):_c('v-switch',{attrs:{"label":_vm.meta.fields.default.verbose_name},model:{value:(field.fieldDefault),callback:function ($$v) {_vm.$set(field, "fieldDefault", $$v)},expression:"field.fieldDefault"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('app-textarea',{attrs:{"counter":_vm.meta.fields.instruction.max_length,"hint":_vm.meta.fields.instruction.help_text,"label":_vm.meta.fields.instruction.verbose_name,"filled":""},model:{value:(field.fieldInstructionText),callback:function ($$v) {_vm.$set(field, "fieldInstructionText", $$v)},expression:"field.fieldInstructionText"}})],1),(field.fieldType === 'Select')?_c('v-col',{attrs:{"cols":"12"}},[_c('app-textarea')],1):_vm._e()],1),_c('v-divider')],1),_c('v-card-actions',{staticStyle:{"height":"50px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.addField(
                                  section.sectionSequence,
                                  field.fieldSequence
                                )}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v("New field")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.duplicateField(
                                  section.sectionSequence,
                                  field.fieldSequence,
                                  field
                                )}}},on),[_c('v-icon',[_vm._v("queue")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplicate field")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":field.fieldSequence === 0 &&
                                section.sectionFields.length === 1},on:{"click":function($event){return _vm.deleteField(
                                  section.sectionSequence,
                                  field.fieldSequence
                                )}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete field")])]),(
                            field.fieldSequence !== 0 &&
                            section.sectionFields.length !== 1
                          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.moveField(
                                  section.sectionSequence,
                                  field.fieldSequence,
                                  'up'
                                )}}},on),[_c('v-icon',[_vm._v("keyboard_arrow_up")])],1)]}}],null,true)},[_c('span',[_vm._v("Move field up")])]):_vm._e(),(
                            field.fieldSequence + 1 !==
                              section.sectionFields.length &&
                            section.sectionFields.length !== 1
                          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.moveField(
                                  section.sectionSequence,
                                  field.fieldSequence,
                                  'down'
                                )}}},on),[_c('v-icon',[_vm._v("keyboard_arrow_down")])],1)]}}],null,true)},[_c('span',[_vm._v("Move field down")])]):_vm._e(),_c('v-spacer'),_c('v-switch',{staticClass:"pr-2",attrs:{"label":_vm.meta.fields.mandatory.verbose_name},model:{value:(field.fieldMandatory),callback:function ($$v) {_vm.$set(field, "fieldMandatory", $$v)},expression:"field.fieldMandatory"}}),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("more_vert")])],1)],1)],1)],1)],1)}),1)],1)],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }