export const mode = {
  info: {
    color: 'info',
    icon: 'info',
    iconColor: 'secondary'
  },
  error: {
    color: 'error',
    icon: 'error',
    iconColor: 'secondary'
  },
  success: {
    color: 'success',
    icon: 'done',
    iconColor: 'secondary'
  },
  warning: {
    color: 'warning',
    icon: 'warning',
    iconColor: 'secondary'
  }
}
