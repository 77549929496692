import banner from './globals/banner'
import overlay from './globals/overlay'
import snackbar from './globals/snackbar'

const state = {}

const getters = {}

const actions = {}

const mutations = {}

const modules = {
  banner,
  overlay,
  snackbar
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules
}
