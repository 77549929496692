/* eslint no-prototype-builtins: 0 */
<template>
  <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    scrollable
    transition="dialog-bottom-transition"
    @keydown.esc="close()"
  >
    <v-card flat>
      <v-card-title class="pa-0 ma-0">
        <!-- dialog toolbar -->
        <v-toolbar color="#385F73">
          <v-toolbar-title
            >{{ internal.form }} - V{{ internal.version }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn plain @click="complete()">Complete</v-btn>
            <v-btn icon @click="close()">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!--<v-progress-linear
          :value="
            (executionFields.filter((field) => !field.initial).length /
              executionFields.length) *
            100
          "
        ></v-progress-linear>-->

        <!-- form content -->
        <!-- header -->
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" v-for="section in sections" :key="section.section">
              <!-- section -->
              <v-card :disabled="!(section.role === role || section.role === '')" color="#385F73">
                <v-progress-linear
                  color="white"
                  :value="
                    (section.fields.filter((field) => {
                      !field.initial;
                    }).length /
                      section.fields.length) *
                    100
                  "
                />
                <v-card-title>
                  {{ section.section }}
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">done</v-icon>
                    </template>
                    <span>done</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-subtitle
                  >{{ section.role }} -
                  {{ section.confirmation }}</v-card-subtitle
                >
                <v-card-text>
                  <v-row
                    v-for="field in section.fields"
                    :key="field.field"
                    no-gutters
                  >
                    <v-col cols="2"> </v-col>
                    <v-col cols="4">
                      <v-subheader
                        v-if="field.instruction"
                        v-text="field.instruction"
                        class="pa-0 ma-0"
                      />
                      <!-- text field -->
                      <v-speed-dial
                        v-model="field.isFocused"
                        direction="right"
                        open-on-hover
                      >
                        <template v-slot:activator>
                          <!-- text field -->
                          <v-text-field
                            v-if="field.data_type === 'CharField'"
                            v-model="field.value"
                            outlined
                            dense
                            :readonly="!isActive(field)"
                            :success="!isActive(field)"
                            :append-icon="isActive(field) ? 'save' : 'edit'"
                            @click:append="initiatePatch(field)"
                          >
                            <template v-slot:label>
                              <div>
                                {{ field.field
                                }}<span v-if="field.mandatory" class="red--text"
                                  >*</span
                                >
                              </div>
                            </template>
                            <template v-slot:append>
                              <v-btn
                                v-if="field.isEditing"
                                color="default"
                                icon
                                @click="reset(field)"
                              >
                                <v-icon v-text="'clear'"></v-icon>
                              </v-btn>
                              <v-btn
                                color="default"
                                icon
                                @click="initiatePatch(field)"
                              >
                                <v-icon
                                  v-text="isActive(field) ? 'save' : 'edit'"
                                ></v-icon>
                              </v-btn>
                            </template>
                          </v-text-field>

                          <!-- boolean field -->
                          <v-switch
                            v-if="field.data_type === 'BooleanField'"
                            v-model="field.value"
                            outlined
                            dense
                            :success="!isActive(field)"
                            :readonly="!isActive(field)"
                          >
                            <template v-slot:label>
                              <div>
                                {{ field.field
                                }}<span v-if="field.mandatory" class="red--text"
                                  >*</span
                                >
                              </div>
                            </template>
                            <template v-slot:append>
                              <v-btn
                                v-if="field.isEditing"
                                color="default"
                                icon
                                @click="reset(field)"
                              >
                                <v-icon v-text="'clear'"></v-icon>
                              </v-btn>
                              <v-btn
                                color="default"
                                icon
                                @click="initiatePatch(field)"
                              >
                                <v-icon
                                  v-text="isActive(field) ? 'save' : 'edit'"
                                ></v-icon>
                              </v-btn>
                            </template>
                          </v-switch>
                        </template>
                        <div>
                          <!-- save / edit -->
                          <!--<v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      fab
                      x-small
                      @click="initiatePatch(field)"
                    >
                      <v-icon v-text="isActive(field) ? 'save' : 'edit'" />
                    </v-btn>
                  </template>
                  <span v-text="isActive(field) ? 'Save' : 'Edit'" />
                </v-tooltip>-->
                          <!-- history -->
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                fab
                                x-small
                                :disabled="field.initial"
                                @click="getLogEntries(field)"
                              >
                                <v-icon>event_note</v-icon>
                              </v-btn>
                            </template>
                            <span>Show history</span>
                          </v-tooltip>
                          <!-- clear -->
                          <v-tooltip
                            v-if="field.data_type === 'CharField'"
                            right
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                :disabled="!field.initial"
                                fab
                                x-small
                                @click="initiatePatch(field, true)"
                              >
                                <v-icon>clear</v-icon>
                              </v-btn>
                            </template>
                            <span>Devalue</span>
                          </v-tooltip>
                        </div>
                      </v-speed-dial>
                      <p></p>
                    </v-col>
                    <v-col cols="6"> </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="section.confirmation === 'signature'"
                        v-bind="attrs"
                        v-on="on"
                        text
                        @click="initStatusSection(section, 'approve')"
                        >Approve</v-btn
                      >
                    </template>
                    <span>approve section</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="section.confirmation === 'signature'"
                        v-bind="attrs"
                        v-on="on"
                        text
                        @click="initStatusSection(section, 'reject')"
                        >Reject</v-btn
                      >
                    </template>
                    <span>reject section</span>
                  </v-tooltip>
                  <!--<v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" text
                        ><v-icon>event_note</v-icon></v-btn
                      >
                    </template>
                    <span>show history</span>
                  </v-tooltip>-->
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
      </v-card-actions>
      <!-- signature dialog -->
      <app-signature
        :dialog="sigDialog"
        sig="signature"
        com="optional"
        :error="errorMsgs"
        @close-dialog="sigDialog = $event"
        @get-signature="addSignature($event)"
      />

      <!-- execution log -->
      <execution-log
        :dialog="logDialog"
        :entries="logEntries"
        @close-dialog="logDialog = $event"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";

import appSignature from "@/components/TheSignature";
import executionLog from "@/components/ExecutionLog";

export default {
  props: {
    val: {
      type: Object,
    },
    dialog: {
      type: Boolean,
    },
    misc: {
      type: Object,
      /*
      { sig: 'logging',
        com: 'none'
      }
      */
    },
  },

  data() {
    return {
      internal: {},
      executionFields: [],
      sections: [],
      devalueDefault: "---",
      sigDialog: false,
      logDialog: false,
      errorMsgs: {},
      logEntries: [],
      role: "",
      section: "",
      stat: "",
    };
  },

  computed: {
    formHeader() {
      return [
        { text: "Order number", value: this.internal.number },
        { text: "Form title", value: this.internal.form },
        { text: "Form version", value: this.internal.version },
        { text: "Form status", value: this.internal.status },
      ];
    },
    /*
    sortedFields () {
      const sortedFields = _.cloneDeep(this.internalData.fields_values)
      return _.sortBy(sortedFields, ['section'])
    }
    */
  },

  components: {
    appSignature,
    executionLog,
  },

  methods: {
    ...mapActions({
      // snackbar
      activate: "global/snackbar/activate",
    }),
    // TDOD: complete
    // initiate internal data
    tst() {},
    patch(field, devalue = false) {
      const payload = { value: devalue ? this.devalueDefault : field.value };
      this.$http
        .patch(
          `rtd/execution/${this.internal.number}/${field.section}/value/${field.field}`,
          payload
        )
        .then(() => {
          this.activate({
            color: "success",
            message: [`Value was successfully saved to field "${field.field}"`],
          });
          if (devalue) field.value = this.devalueDefault;
          field.isEditing = false;
          field.initial = false;
          field.tmp = payload.value;
        })
        .catch((err) => {
          if (err.response.data.hasOwnProperty("validation_errors"))
            this.activate({
              color: "error",
              message: err.response.data.validation_errors,
            });
        });
    },
    initStatusSection(section, status) {
      if (section.confirmation === "signature" && status === "approve") {
        this.section = Object.assign({}, section);
        this.stat = status;
        this.sigDialog = true;
      } else {
        this.setStatusSection(section, status);
      }
    },
    setStatusSection(section, status) {
      this.$http
        .post(
          `rtd/execution/${this.internal.number}/${section.section}/action/${status}`
        )
        .catch((err) => {
          if (err.response.data.hasOwnProperty("validation_errors"))
            this.activate({
              color: "error",
              message: err.response.data.validation_errors,
            });
        });
    },
    tst() {
      this.$http.get(
        `/logs/execution_values?limit=100&offset=0&order_by=timestamp.desc&number=exact.${this.internal.number}&section=exact.${field.section}&field=exact.${field.field}&and_or=and`
      );
    },
    getLogEntries(field) {
      this.$http
        .get(
          `/logs/execution_values?limit=100&offset=0&order_by=timestamp.desc&number=exact.${this.internal.number}&section=exact.${field.section}&field=exact.${field.field}&and_or=and`
        )
        .then((resp) => {
          if (resp.data.count) {
            this.logEntries = resp.data.results;
            this.logDialog = true;
          } else {
            this.activate({
              color: "error",
              message: ["No log data available"],
            });
          }
        });
    },
    close() {
      this.$emit("close", false);
    },
    save() {},
    isActive(field) {
      if (!field.isEditing && !field.initial) return false;
      return true;
    },
    initiatePatch(field, devalue = false) {
      // this.$set(field, 'isEditing', !field.isEditing)
      if (this.isActive(field)) this.patch(field, devalue);
      else field.isEditing = true;
    },
    reset(field) {
      field.value = field.tmp;
      field.isEditing = false;
    },
    addSignature(sig) {
      this.$http
        .post(
          `rtd/execution/${this.internal.number}/${this.section.section}/action/${this.stat}`,
          sig
        )
        .then(() => {
          this.sigDialog = false;
          this.activate({
            color: "success",
            message: ["Success"],
          });
        })
        .catch((err) => {
          if (err.response.data.hasOwnProperty("validation_errors"))
            this.activate({
              color: "error",
              message: err.response.data.validation_errors,
            });
        });
    },
    // complete execution of form by transitioning status to complete
    complete() {
      this.$http
        .patch(`rtd/execution/${this.internal.number}/complete`)
        .then(() => {
          this.$emit("close", false);
        })
        .catch((err) => {
          if (err.response.data.hasOwnProperty("validation_errors"))
            this.activate({
              color: "error",
              message: err.response.data.validation_errors,
            });
            this.sigDialog = false
        });
    },
  },

  watch: {
    val: {
      immediate: true,
      handler(newValue) {
        // check if prop is empty
        if (!_.isEmpty(newValue)) {
          // copy prop data
          this.internal = _.cloneDeep(newValue);
          // iterate sections
          for (let section of this.internal["sections"]) {
            // make copy of section and assign to tmp section obj
            let _section = Object.assign({ fields: [] }, section);
            // iterate fields
            for (let field of this.internal["fields_values"]) {
              // check if field belongs in section
              if (field.section === section.section) {
                let _field;
                if (field.data_type === "Charfield") {
                  _field = field.value ? field.value : field.default;
                } else {
                  _field = field.value === null ? field.value : field.default;
                }
                _section.fields.push(
                  Object.assign(
                    Object.assign(
                      {
                        isEditing: false,
                        isFocused: false,
                        initial: !field.value,
                        tmp: field.value,
                      },
                      field
                    ),
                    { value: _field }
                  )
                );
              }
            }
            this.sections.push(Object.assign({}, _section));
          }

          for (let field of this.internal["fields_values"]) {
            var val = null;
            if (field.data_type === "Charfield") {
              val = field.value ? field.value : field.default;
            } else {
              val = field.value === null ? field.value : field.default;
            }
            this.executionFields.push(
              Object.assign(
                Object.assign(
                  {
                    isEditing: false,
                    isFocused: false,
                    initial: !field.value,
                    tmp: field.value,
                  },
                  field
                ),
                { value: val }
              )
            );
          }
        }
      },
    },
    dialog: {
      handler(val) {
        if (!val) {
          this.internal = {};
          this.executionFields = [];
          this.sections = [];
        }
      },
    },
  },

  mounted() {
    this.$http.get("/admin/settings/core-devalue").then((resp) => {
      this.devalueDefault = resp.data.results[0].value;
    });
    this.$http.get("/admin/users?username=admin").then((resp) => {
      this.role = resp.data.results[0].username;
    });
  },
};
</script>
