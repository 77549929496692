export const INIT_MASTERDATA = 'INIT_MASTERDATA'
export const NEW_MASTERDATA = 'NEW_MASTERDATA'
export const EDIT_MASTERDATA = 'EDIT_MASTERDATA'
export const DELETE_MASTERDATA = 'DELETE_MASTERDATA'
export const NEWV_VERSION_MASTERDATA = 'NEWV_VERSION_MASTERDATA'
export const STATE_CHANGE_MASTERDATA = 'STATE_CHANGE_MASTERDATA'
export const LOAD_META_MASTERDATA = 'LOAD_META_MASTERDATA'
export const LOAD_DATA_MASTERDATA = 'LOAD_DATA_MASTERDATA'
export const RELOAD_MASTERDATA = 'RELOAD_MASTERDATA'
export const ACTIVATE_DIALOG_MASTERDATA = 'ACTIVATE_DIALOG_MASTERDATA'
